import React from 'react'
import PropTypes from 'prop-types'

import OffSiteLink from './off-site-link'

const appStoreUrl = "https://itunes.apple.com/us/app/harper-dog-training/id1338605261?mt=8"

const AppStoreLink = ({
  children,
  className,
  id,
  onClick,
}) => {
  return (
    <OffSiteLink
      href={appStoreUrl}
      onClick={onClick}
      className={className}
      id={id}
    >
      {children}
    </OffSiteLink>
  )
}

export default AppStoreLink

AppStoreLink.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  id: PropTypes.string,
  onClick: PropTypes.func,
}
