import { Link } from "gatsby"
import React from "react"
import PropTypes from "prop-types"

import AppStoreLink from '@components/app-store-link'
import Navbar from '@components/navbar'

const Header = ({
  children,
  showNavigation,
  className,
}) => (
  <header className={className}>
    <div className="container container--lg">
      <div className="headerWrap">
        <Link className='harper-logo' to="/">Harper</Link>
        {children}
        {showNavigation && (
          <Navbar>
            <ul>
              <li>
                <Link to="/about/">About</Link>
              </li>
              {' '}
              <li>
                <Link to="/articles/">Articles</Link>
              </li>
              {' '}
              <li>
                <Link to="/help/">Help</Link>
              </li>
              {' '}
              <li className='small-menu-only'>
                <Link to="/press/">Press</Link>
              </li>
              {' '}
              <li className='small-menu-only'>
                <Link to="/android/">Android</Link>
              </li>
              {' '}
              <li className='small-menu-only'>
                <Link to="/foster/">Foster</Link>
              </li>
              {' '}
              <li className="downloadLinkItem">
                <AppStoreLink id="globalHeaderCTA">Download</AppStoreLink>
              </li>
            </ul>
          </Navbar>
        )}
      </div>
    </div>
  </header>
)

export default Header

Header.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  showNavigation: PropTypes.any,
}

Header.defaultProps = {
  className: 'global-header',
  showNavigation: null,
}
