import React from 'react';
import PropTypes from 'prop-types';
import HamburgerMenu from 'react-hamburger-menu';
import Media from 'react-media';
import useToggle from 'react-use-toggle';

export default function Navbar({ children }) {
  const [showSmallMenu, toggleShowSmallMenu] = useToggle(false);

  return (
    <Media queries={{
      small: "(max-width: 599px)",
      large: "(min-width: 600px)",
    }}>
      {matches => (
        <>
          {matches.large && (
            <nav className="large">
              {children}
            </nav>
          )}
          {matches.small && (
            <nav className="small">
              <div className="hamburger">
                <HamburgerMenu
                  isOpen={showSmallMenu}
                  menuClicked={toggleShowSmallMenu}
                  width={20}
                  height={17}
                  strokeWidth={2}
                  rotate={0}
                  color='white'
                  borderRadius={0}
                  animationDuration={0.5}
                />
              </div>
              {showSmallMenu ? children : null}
            </nav>
          )}
        </>
      )}
    </Media>
  );
}

Navbar.propTypes = {
  children: PropTypes.node.isRequired,
};
