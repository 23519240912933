import React from 'react'
import PropTypes from 'prop-types'

const OffSiteLink = ({
  children,
  className,
  href,
  id,
  onClick,
}) => {
  return (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      onClick={onClick}
      className={className}
      id={id}
    >
      {children}
    </a>
  )
}

export default OffSiteLink

OffSiteLink.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  href: PropTypes.string,
  id: PropTypes.string,
  onClick: PropTypes.func,
}
