import { Link } from "gatsby"
import React from "react"

import AppStoreLink from '@components/app-store-link'
import OffSiteLink from '@components/off-site-link'

const Footer = () => (
  <footer className="global-footer">
    <div className="container container--lg">
      <Link to="/" className="harper-logo">Harper</Link>
      <div className="footerWrap">
        <div className="footerColumn">
          <span className="listTitle">Learn</span>
          <nav>
            <ul>
              <li>
                <Link to="/about/">About</Link>
              </li>
              <li>
                <Link to="/articles/">Articles</Link>
              </li>
              <li>
                <Link to="/press/">Press</Link>
              </li>
              <li>
                <Link to="/foster/">Foster</Link>
              </li>
              <li>
                <Link to="/android/">Android</Link>
              </li>
              <li>
                <AppStoreLink>Download</AppStoreLink>
              </li>
            </ul>
          </nav>
        </div>
        <div className="footerColumn">
          <span className="listTitle">Social</span>
          <nav>
            <ul>
              <li>
                <OffSiteLink href="https://www.instagram.com/harperdogapp/">Instagram</OffSiteLink>
              </li>
              <li>
                <OffSiteLink href="https://twitter.com/harperdogapp">Twitter</OffSiteLink>
              </li>
              <li>
                <OffSiteLink href="https://www.facebook.com/harperdogs/">Facebook</OffSiteLink>
              </li>
            </ul>
          </nav>
        </div>
        <div className="footerColumn">
          <span className="listTitle">Help</span>
          <nav>
            <ul>
              <li>
                <a href="sms:+1-513-818-2190">Live Chat</a>
              </li>
              <li>
                <a href="mailto:nick@harper.dog?subject=%E2%9C%8B%20Harper%20help&body=Hey%20Harper%2C%20I%20could%20use%20a%20hand.%20I%20wanted%20to%20let%20you%20know...%0D%0A">Drop a Note</a>
              </li>
              <li>
                <Link to="/help/">Help Center</Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>

    <div className="footerFooter">
      <div className="container container--lg">
        <Link to="/terms/">Terms</Link>
        <Link to="/privacy/">Privacy</Link>
        <Link to="/contact/">Contact</Link>
        <small>&copy; Harper Pack LLC. All rights reserved.</small>
      </div>
    </div>
  </footer>
)

Footer.propTypes = {
}

Footer.defaultProps = {
}

export default Footer
